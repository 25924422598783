import { useRouter as _useRouter, type NextRouter } from 'next/router'

import { useEffect, useMemo } from 'react'

export const isReplacingRouterRef = {
  current: false,
}

export const useRouter = () => {
  const _router = _useRouter()
  const router: NextRouter = useMemo(
    () => ({
      ..._router,
      replace: (...args) => {
        isReplacingRouterRef.current = true
        return _router.replace(...args)
      },
    }),
    [_router],
  )

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      isReplacingRouterRef.current = false
    }

    _router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      _router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }

    // This effect is meant to run only once to subscribe to the event listener for replacing logic.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_router.events])

  return router
}
