import type { InferGetStaticPropsType } from 'next'
import { NextSeo } from 'next-seo'

import { isWuAppWebview } from '@libs-components/utils/device'
import Homepage from './home'
import getStaticProps from './home/getStaticProps'
import { useRouter } from '@/hooks/use-router'

type PageProps = InferGetStaticPropsType<typeof getStaticProps>
const Home = ({ pageComponents }: PageProps) => {
  const { replace } = useRouter()
  if (isWuAppWebview()) {
    replace('/app-home')
  }

  return (
    <>
      <NextSeo canonical={process.env.NEXT_PUBLIC_SELF_DOMAIN} />
      <Homepage pageComponents={pageComponents?.data} />
    </>
  )
}

export default Home
export { getStaticProps }
